import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { getDoc, doc, updateDoc, query, onSnapshot, getDocs, collection, where, addDoc, orderBy } from 'firebase/firestore';
import { db } from '../../../../firebase';
import { getDateInfo } from '../../../utility/timestamp';
import CircularProgress from "react-cssfx-loading/lib/CircularProgress";
import { liveBeginnersCoursesData } from '../../../utility/presetdata';
import LiveBeginnersCourseLessonCard from './LiveBeginnersCourseLessonCard';
import { useAuth } from '../../../../contexts/AuthContext';



export default function LiveBeginnersCourseSessions(props) {
  
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const [data, setData] = useState();
  const [loading, setLoading] = useState(true);
  const [stripePriceLoading, setStripePriceLoading] = useState(false);
  const [error, setError] = useState();
  const [sessionStatus, setSessionStatus] = useState();
  const [dateInfo, setDateInfo] = useState({});
  const [lessonsData, setLessonsData] = useState([]);
  const [stripePriceTag, setStripePriceTag] = useState(true);
  const buttonRef = useRef();
  const stripeButtonRef = useRef();
  const stripePriceTagRef = useRef();
  const [markasCompleteLoading, setMarkasCompleteLoading] = useState(false);


  useEffect( async () => {

    let docId = '';
    const thisProgramDoc = await getDoc(doc(db, 'courses', 'JwwXQV40eM2NUXSTOoVx', 'programs', props.sessionId))
    setData(thisProgramDoc)
    docId = thisProgramDoc.id;
    setSessionStatus(thisProgramDoc.data().Status)
    setDateInfo(getDateInfo(thisProgramDoc.data().startDateUnix))
    
    const unsubscribe = onSnapshot(query(collection(db, 'courses', 'JwwXQV40eM2NUXSTOoVx', 'programs', docId, 'sessions'), orderBy('Lesson')), (lessonsDocs) => {
      let tempLessonsData = [];
      let i = 0;
      lessonsDocs.forEach((doc) => {
        if (i == 0) { 
          setDateInfo(getDateInfo(doc.data().unixTimeDateStamp)) 
        }
        tempLessonsData.push(doc);
        i += 1
      })
      setLessonsData([...tempLessonsData]);
      setLoading(false)
    })
    
    // .then(() => {
    //   getDoc(doc(db, 'courses', 'JwwXQV40eM2NUXSTOoVx', 'programs', props.sessionId))
    //   .then((doc) => {
    //     if (!doc.data().stripePriceTag) {
    //       setStripePriceTag(false)
    //     }
    //   })
    // })
    // .catch((error) => {
    //   console.error("Error fetching data: " + error);
    //   setError(error);
    // })
    // .finally(() => {
    //   setLoading(false);
    // })
    return () => {
      unsubscribe();
    }
  }, [])


  // useEffect(() => {

  //   let docId = '';
  //   let tempLessonsData = [];
  //   getDoc(doc(db, 'courses', 'JwwXQV40eM2NUXSTOoVx', 'programs', props.sessionId))
  //   .then((doc) => {
  //     setData(doc)
  //     docId = doc.id;
  //     setSessionStatus(doc.data().Status)
  //     setDateInfo(getDateInfo(doc.data().startDateUnix))
  //   })
  //   .then(() => {
  //     const qLessons = query(collection(db, 'courses', 'JwwXQV40eM2NUXSTOoVx', 'programs', docId, 'sessions'), orderBy('Lesson'))
  //     getDocs(qLessons)
  //     .then((docs) => {
  //       let i = 0;
  //       docs.forEach((doc) => {
  //         if (i == 0) { setDateInfo(getDateInfo(doc.data().unixTimeDateStamp)) }
  //         i += 1;
  //         tempLessonsData.push(doc);
  //       })
  //     setLessonsData(tempLessonsData);
  //     })
  //   })
  //   .then(() => {
  //     getDoc(doc(db, 'courses', 'JwwXQV40eM2NUXSTOoVx', 'programs', props.sessionId))
  //     .then((doc) => {
  //       if (!doc.data().stripePriceTag) {
  //         setStripePriceTag(false)
  //       }
  //     })
  //   })
  //   .catch((error) => {
  //     console.error("Error fetching data: " + error);
  //     setError(error);
  //   })
  //   .finally(() => {
  //     setLoading(false);
  //   })

  // }, [reFetch])

  function handleMarkAsComplete() {

    if (buttonRef.current.innerHTML == "Mark as complete") {
      buttonRef.current.innerHTML = "Confirm?";
    }
    else if (buttonRef.current.innerHTML == "Confirm?") {

      buttonRef.current.innerHTML = "";
      setMarkasCompleteLoading(true);

      currentUser.getIdToken(true)
      .then(function(idToken) {
        // console.log(idToken)
        // return


        var myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${idToken}`);

        var requestOptions = {
          method: 'POST',
          headers: myHeaders,
          redirect: 'follow'
        };

        fetch("https://us-central1-wow-by-ann.cloudfunctions.net/newLiveBeginnersSession", requestOptions)
          .then(response => response.text())
          .then((result) => {
            // navigate('/admin/livebeginnerscourse')
            console.log(result)
          })
          .catch(error => console.log('error', error));
      })
    }
  }
  

  function handleSetStripePriceTag(e) {
    e.preventDefault();
    if (stripeButtonRef.current.innerHTML == "Set Price") {
      stripeButtonRef.current.innerHTML = "Confirm?";
    }
    else if (stripeButtonRef.current.innerHTML == "Confirm?") {
      setStripePriceLoading(true);
      updateDoc(doc(db, 'courses', 'JwwXQV40eM2NUXSTOoVx', 'programs', data.id), {
        stripePriceTag: stripePriceTagRef.current.value
      })
      .then(() => {
        window.location.reload(false);
      })
    }
  }


  if (loading) {
    return <></>;
  }
  

  if (sessionStatus == "past") {
    return (
      <>
        <h2 className="dashboard-section-title">{'Session: ' + dateInfo.Month + ' ' + dateInfo.year}</h2>
        <h4>This session is no longer active and you can't make any changes to it anymore.</h4>
      </>
    )
  }
  

  if (sessionStatus == "future") {
    
    if (!stripePriceTag) { 
      return (
        <>
        <h2 className="dashboard-section-title">{'Session: ' + dateInfo.Month + ' ' + dateInfo.year}</h2>
        <h4>This is the first upcoming session- make sure everything here is set before you mark the active session as complete.</h4>
        <div className="live-beginners-course-session-card-admin">
          
        <form onSubmit={handleSetStripePriceTag} >
            <div className="auth-form-group">
                <label htmlFor="" className="auth-form-label">Stripe Price (price_xxxxxxxxxxxxxxxxxxx):</label>
                <input ref={stripePriceTagRef} type="text" className="admin-form-input" defaultValue={data.data().Focus} />
            </div>
            <button disabled={stripePriceLoading} className="button-stretch" ref={stripeButtonRef}>Set Price</button>
        </form>
  
        </div>
        <div className="live-beginners-course-sessions-admin-card-wrapper">
          {lessonsData.map((lesson, i) => {
            return <LiveBeginnersCourseLessonCard session={props.sessionId} key={i} doc={lesson}/>
          })}
          
        </div>
        </>
      )
    }

    else {
      return (
        <>
        <h2 className="dashboard-section-title">{'Session: ' + dateInfo.Month + ' ' + dateInfo.year}</h2>
        <h4>This is the first upcoming session- make sure everything here is set before you mark the active session as complete.</h4>
        <div className="live-beginners-course-sessions-admin-card-wrapper">
          {lessonsData.map((lesson, i) => {
            return <LiveBeginnersCourseLessonCard session={props.sessionId} key={i} docData={lesson.data()} doc={lesson}/>
          })}
          
        </div>
        </>
      )
    }
    
  }

  if (sessionStatus == "active") {
    return (
      <>
      <h2 className="dashboard-section-title">{'Session: ' + dateInfo.Month + ' ' + dateInfo.year}</h2>
      <h4>This is the current live beginners course</h4>
      <div className="live-beginners-course-session-card-admin">
        <button onClick={handleMarkAsComplete} ref={buttonRef} className="button-stretch" disabled={markasCompleteLoading}>
          {(markasCompleteLoading) ? <CircularProgress color="#ffffff" width="18px" height="18px" /> : "Mark as complete"}
        </button>
      </div>
      <div className="live-beginners-course-sessions-admin-card-wrapper">
        {lessonsData.map((lesson, i) => {
          return <LiveBeginnersCourseLessonCard session={props.sessionId} key={i} docData={lesson.data()} doc={lesson}/>
        })}
        
      </div>
      </>
    )
  }
}

{/* <button onClick={handleMarkAsComplete} ref={buttonRef} className="course-card-button mark-as-complete-button-admin">
  Mark as complete
</button> */}