import React, { useEffect, useState, useRef } from 'react';
import playButton from '../../../images/play-button.png';
import downloadIcon from '../../../images/download.svg';
import { db } from '../../../firebase';
import { getDocs, collection, orderBy, query } from 'firebase/firestore';
import { getDateInfo } from '../../utility/timestamp';
import { getUrlParams } from '../../utility/urlparams';


export default function BeginnersCourseLiveActive(props) {
  
    const [courseData, setCourseData] = useState(props.data);
    const [courseSessions, setCourseSessions] = useState([]); // array of 16 session docs
    const [latestCourseData, setLatestCourseData] = useState(); // latest lessen that IS completed
    const [rotationIndex, setRotationIndex] = useState("0");
    const [loading, setLoading] = useState(true);
    const [degrees, setDegrees] = useState(0);
    const videoRef = useRef();
    const [currentlyLive, setCurrentlyLive] = useState() // boolean to indicate wether to show the live lesson or the previous lesson
    const [currentDisplayUrl, setCurrentDisplayUrl] = useState()
    const [isFirst, setIsFirst] = useState() // check if the current course is the first course
    const [isLast, setIsLast] = useState() // check if the current course is the last
  
    useEffect( async () => {
        let dataArray = [];
        const sessionsDocs = await getDocs(query(collection(db, courseData.ref.path, 'sessions'), orderBy('Lesson')))
        sessionsDocs.forEach((sessionDoc) => {
            dataArray.push(sessionDoc)
        })
        setCourseSessions(dataArray);
    }, [])

    // Effect for the progress bar
    useEffect(() => {
        if (courseSessions.length == 0) {
            return
        }

        let latestCourseDataLocal = "";
        let currentTime = parseInt(Date.now().toString().slice(0, 10))
        for (let i = (courseSessions.length - 1); i >= 0; i--) {
            // if (currentTime > (courseSessions[i].data().unixTimeDateStamp + 7200)) {
            //     // console.log(courseSessions[i].data().Lesson)
            //     latestCourseDataLocal = courseSessions[i].data().Lesson - 1;
            //     setLatestCourseData(courseSessions[i].data().Lesson - 1)
            //     break
            // }
            if (courseSessions[i].data().Complete) {

                latestCourseDataLocal = courseSessions[i].data().Lesson
                setLatestCourseData(courseSessions[i].data().Lesson)

                if (i == courseSessions.length - 1) {
                    setCurrentlyLive(false)
                    setIsLast(true)
                }
                else {
                    if (getDateInfo(courseSessions[i + 1].data().unixTimeDateStamp).localUnix > currentTime) {
                        setCurrentlyLive(false)
                    }
                    else { setCurrentlyLive(true) }
                }
                break
            }

            if (i == 0) {
                setIsFirst(true)
                setLatestCourseData(0)
                if (getDateInfo(courseSessions[0].data().unixTimeDateStamp).localUnix > currentTime) {
                    setCurrentlyLive(false)
                }
                else { setCurrentlyLive(true) }
                break
            }
        }

        let percentage = latestCourseDataLocal / 16 * 100
        console.log(percentage)
        if (percentage < 33.3) {
            setRotationIndex("1");
            setDegrees(percentage * (90/33.3))
        }
        else if (percentage < 66.6 && percentage > 33.3) {
            setRotationIndex("2");
            setDegrees((percentage - 33.3) * (90/33.3))
        }
        else if (percentage <= 100 && percentage > 66.6) {
            setRotationIndex("3");
            setDegrees((percentage - 66.6) * (90/33.3))
        }
        setLoading(false);

    }, [courseSessions])


    function handleStartVideo() {
        // console.log(videoRef.current)
        videoRef.current.style.display = "flex";
    }

    if (loading) {
        return <></>
    }

    // if (isFirst) {
    //     return <></>
    // }
    
    else if (currentlyLive) {
        // (Math.round(new Date().getTime() / 1000)) > getDateInfo(courseSessions[latestCourseData].data().unixTimeDateStamp).localUnix && (!courseSessions[latestCourseData].data().Complete)
        
        let videoURL = courseSessions[latestCourseData].data().LessonUrl;
        let urlQueryString = videoURL.split('?').pop();
        let videoUrlId = new URLSearchParams(urlQueryString).get('v');


        return (
            <>
                <a target="_blank" className="file-download-card-wrapper" download="proposed_file_name" href='https://cdn.wowbyann.com/files/Your%20Healthy%20DRESS%20for%20Success.pdf'>
                    <img src={downloadIcon} alt="" className="download-icon" />
                    <h3 className="file-download-text">
                    Download Nutrition- cheat sheet
                    </h3>
                </a>
                <div className="dashboard-section-wrapper">
                    <h2 className="dashboard-section-title">
                    Course Progress
                    </h2>
                    <div className="beginners-live-progress-wrapper">
                        <div className="progress-bar-wrapper">
                            <div className="progress-outer-circle"></div>
                            <div className={`progress-inner-circle progress-inner-circle-${rotationIndex}`} style={{ transform: `rotate(${degrees}deg)` }}></div>
                            <div className="progress-bar-overlay"></div>
                        </div>
                        <div className="progress-bar-text-wrapper">
                            <h2 className="progress-bar-title">you completed {latestCourseData}/16 session</h2>
                            {/* <p className="progress-bar-subtitle">24/50 hours watched</p> */}
                        </div>
                    </div>
                    <div className="beginners-live-sessions-cards-wrapper">
                        <a id="beginnersCoursePreviousSession" href="#" onClick={handleStartVideo} className="beginners-course-card" style={{ textDecoration: "none" }}>
                            <div className="beginners-course-previous-session-overlay">
                            </div>
                            <img src={playButton} alt="" className="play-button-image" />
                            <h2 className="beginners-course-card-title">
                                Lesson is Live<br/>Join now
                            </h2>
                            <iframe ref={videoRef} className="youtube-video-embed" src={`https://www.youtube.com/embed/${videoUrlId}?modestbranding=0`} title="YouTube video player" 
                            frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen>
                            </iframe>
                        </a>
                    </div>
                </div>
            </>
        );
    }

    else {

            let nextCourseData = !isLast && courseSessions[latestCourseData].data();
            let nextCourseDate =  !isLast && getDateInfo(courseSessions[latestCourseData].data().unixTimeDateStamp);
            let videoURL = !isFirst && courseSessions[latestCourseData - 1].data().LessonUrl;
            let urlQueryString = !isFirst && videoURL.split('?').pop();
            let videoUrlId = !isFirst && new URLSearchParams(urlQueryString).get('v');


            return (
            <>
                <a target="_blank" className="file-download-card-wrapper" download="proposed_file_name" href='https://cdn.wowbyann.com/files/Your%20Healthy%20DRESS%20for%20Success.pdf'>
                    <img src={downloadIcon} alt="" className="download-icon" />
                    <h3 className="file-download-text">
                    Download Nutrition- cheat sheet
                    </h3>
                </a>
                <div className="dashboard-section-wrapper">
                    <h2 className="dashboard-section-title">
                    Course Progress
                    </h2>
                    <div className="beginners-live-progress-wrapper">
                        <div className="progress-bar-wrapper">
                            <div className="progress-outer-circle"></div>
                            <div className={`progress-inner-circle progress-inner-circle-${rotationIndex}`} style={{ transform: `rotate(${degrees}deg)` }}></div>
                            <div className="progress-bar-overlay"></div>
                        </div>
                        <div className="progress-bar-text-wrapper">
                            <h2 className="progress-bar-title">{latestCourseData}/16 session done</h2>
                            {/* <p className="progress-bar-subtitle">24/50 hours watched</p> */}
                        </div>
                    </div>
                    <div className="beginners-live-sessions-cards-wrapper">
                    {!isLast && 
                    <div className="beginners-course-card">
                        <div className="beginners-course-card-content">
                            <h2 className="beginners-course-card-title">
                            Next online session:
                            </h2>
                            <div className="beginners-course-card-description">
    
                            {nextCourseDate.month}/{nextCourseDate.day}/{nextCourseDate.year} ➞ {nextCourseDate.hours}:{nextCourseDate.minutes}h
                            </div>
                            <h2 className="beginners-course-card-title">
                            Focus:
                            </h2>
                            <div className="beginners-course-card-description">
                            {nextCourseData.Focus}
                            </div>
                        </div>
                    </div>
                    }
                    {!isFirst && 
                    <a id="beginnersCoursePreviousSession" href="#" onClick={handleStartVideo} className="beginners-course-card" style={{ textDecoration: "none" }}>
                        <div className="beginners-course-previous-session-overlay">
                        </div>
                        <img src={playButton} alt="" className="play-button-image" />
                        <h2 className="beginners-course-card-title">
                            Re-watch the<br/>previous session
                        </h2>
                    <iframe ref={videoRef} className="youtube-video-embed" src={`https://www.youtube.com/embed/${videoUrlId}?modestbranding=0`} title="YouTube video player" 
                    frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen>
                    </iframe>
                    </a>
                    }
                    </div>
                </div>
            </>
      );
    
    }
}
