import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getFunctions } from 'firebase/functions';

// const app = firebase.initializeApp({
//     apiKey: "AIzaSyAT5KOF9wQbH23naKpVjkyKyra3bA4zq5k",
//     authDomain: "travel-expenses-6fff4.firebaseapp.com",
//     projectId: "travel-expenses-6fff4",
//     storageBucket: "travel-expenses-6fff4.appspot.com",
//     messagingSenderId: "136928141318",
//     appId: "1:136928141318:web:1fb7e494f1b9e2069f2387",
//     measurementId: "G-MDTL94BWHQ"
// })

// export const auth = app.auth();
// export default app;



// v2

const firebaseConfig = {
  apiKey: "AIzaSyBiKuFSW7TCCeyOIx3TKAKvib39JgCYJMc",
  authDomain: "wow-by-ann.firebaseapp.com",
  projectId: "wow-by-ann",
  storageBucket: "wow-by-ann.appspot.com",
  messagingSenderId: "914529400610",
  appId: "1:914529400610:web:0dbbd47ba7c0d07939577b",
  measurementId: "G-H92MDSPLQC"
  };

const app = initializeApp(firebaseConfig);
export const auth = getAuth();
export const db = getFirestore();
export const functions = getFunctions(app, 'us-west2');
export default app;