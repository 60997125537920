import React from 'react';
import { BrowserRouter as Router, Routes, Route,  } from "react-router-dom";
import { AuthProvider } from '../contexts/AuthContext';
import PrivateRoute from './routes/PrivateRoute';
import AuthenticatedRoute from './routes/AuthenticatedRoute';
import AdminRoute from './routes/AdminRoute';
import Courses from './app/Courses';
import BeginnersCourse from './app/BeginnersCourse';
import Signup from './authentication-flow/Signup';
import Login from './authentication-flow/Login';
import Console from './console/Console';
import AdminLiveBeginnersCourse from './console/tabs/LiveBeginnersCourse';
import AdminRecordedBeginnersCourse from './console/tabs/RecordeBeginnersCourse';
import AdminCourses from './console/tabs/Courses';

function App() {
    return (
        <AuthProvider>
            <Router>
                <Routes>
                    <Route path='/signup' element={ <Signup /> } ></Route>
                    <Route path='/login' element={ <AuthenticatedRoute><Login /></AuthenticatedRoute> } ></Route>
                    
                    <Route exact path='/' element={ <PrivateRoute><Courses /></PrivateRoute> } ></Route>
                    <Route exact path='/beginners-course' element={ <PrivateRoute><BeginnersCourse /></PrivateRoute> } ></Route>


                    <Route exact path='/admin' element={ <AdminRoute><AdminCourses /></AdminRoute> } ></Route>
                    <Route exact path='/admin/livebeginnerscourse' element={ <AdminRoute><AdminLiveBeginnersCourse /></AdminRoute> } ></Route>
                    <Route exact path='/admin/livebeginnerscourse/:id' element={ <AdminRoute><AdminLiveBeginnersCourse /></AdminRoute> } ></Route>
                    <Route exact path='/admin/recordedbeginnerscourse' element={ <AdminRoute><AdminRecordedBeginnersCourse /></AdminRoute> } ></Route>
                    <Route exact path='/admin/courses' element={ <AdminRoute><AdminCourses /></AdminRoute> } ></Route>
                </Routes>
            </Router>
        </AuthProvider>
    );
  }
  
  export default App;
