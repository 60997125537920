import moment from 'moment'


export function getDateInfo(generalUnixTimeStamp) {
    // const offset = new Date().getTimezoneOffset();
    
    // let unixTimeStamp = generalUnixTimeStamp + (offset * 60)
    let unixTimeStamp = generalUnixTimeStamp
    
    function formatSmallValues(number) {
        return ('0' + number).slice(-2)
    }

    // Formatting DateString
    const monthNames = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
    ];
    const dayNames = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const DateData = new Date(unixTimeStamp * 1000);
    let courseDate = {
        day: formatSmallValues(DateData.getDate()),
        Day: dayNames[DateData.getDay()],
        month: formatSmallValues(DateData.getMonth() + 1),
        Month: monthNames[DateData.getMonth()],
        year: DateData.getFullYear(),
        hours: formatSmallValues(DateData.getHours()),
        minutes: formatSmallValues(DateData.getMinutes()),
        localUnix: unixTimeStamp
    }
    
    return courseDate;
    console.log(courseDate.Day)

}

export function getUnixTimeStamp(date, time) {

    let dateArray = date.split('-');
    let timeArray = time.split(':');

    let timeStamp = new Date(Date.UTC(dateArray[0], parseInt(dateArray[1]) - 1, dateArray[2], parseInt(timeArray[0]), timeArray[1]))
    console.log(`old timestamp: ${timeStamp}`)
    // return timeStamp.getTime()/1000;

}




export function fromDateToUnix(date, time) {
    console.log(date, time)
    // let unixTimestamp = moment('2012.08.10', 'YYYY.MM.DD').unix()
    // let unixTimestamp = moment(date, time).unix()
    let unixTimestamp = moment(`${date} ${time}`, 'YYYY-MM-DD hh:mm').unix()
    return unixTimestamp
}