import React, { useState } from 'react';
import { db } from '../../../firebase';
import { useAuth } from '../../../contexts/AuthContext';
import { setDoc, doc, addDoc, getDoc, collection, onSnapshot } from 'firebase/firestore';
import { loadStripe } from '@stripe/stripe-js';

export default function CourseCard(props) {

    const [data, setData] = useState(props.cardInfo.doc.data());
    const [stripePriceTag, setStripePriceTag] = useState(props.cardInfo.stripePriceTag);
    const [mode, setMode] = useState(data.productType);
    const { currentUser } = useAuth();
    const [loading, setLoading] = useState()
    let user = currentUser.uid;

    let buttonText = '';
    if (false) {
        buttonText = 'Go to course';
    }
    else {
        buttonText = data.Button;
    }


    function sendToCheckout() {
        setLoading(true);

        addDoc(collection(db, 'users', user, 'checkout_sessions'), {
            mode: mode,
            price: stripePriceTag,
            success_url: window.location.href,
            cancel_url: window.location.href,
            allow_promotion_codes: true
        })
        .then((docRef) => {
            onSnapshot(docRef, (document) => { 
                const { error, sessionId } = document.data();

                if (error) {
                    alert(error.message);
                }

                if (sessionId) {
                    console.log(sessionId);
                    loadStripe('pk_live_51Jg9zWEJRjyhDLVGEqirbTMNUcMxmkpuC1xq8AlLNasnIEAIitz8wIMMMzNRcx3kxcBipnsU2I2Yb9KYB81PXerq00HqO4dKJ1')
                    .then((stripe) => {
                        stripe.redirectToCheckout({ sessionId });
                    })
                    
                }
            })  
        })
    }




    
    return (
        <div className="course-card">
            <div className="course-card-left" style={{ backgroundImage: `url(${data.Cover})` }}></div>
            <div className="course-card-content">
                <div className="course-card-text-content">
                    <h2 className="course-card-title">
                        {data.Title}
                    </h2>
                    <div className="course-card-description">
                        {data.Description}
                    </div>
                </div>
                <div className="course-card-footer-content">
                    <button onClick={sendToCheckout} className="course-card-button">
                        {loading ? <div className="loader"></div> : buttonText}
                    </button>
                    <a target="_blank" href="https://www.wowbyann.com/services" className="course-card-more-info">
                        more info
                    </a>
                </div>
            </div>
        </div>
    );
}
