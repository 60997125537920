import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getDateInfo } from '../../../utility/timestamp';
import { db } from '../../../../firebase';
import { useAuth } from '../../../../contexts/AuthContext';
import { setDoc, doc, addDoc, getDoc, collection, onSnapshot, query, limit, orderBy, getDocs } from 'firebase/firestore';

export default function LiveBeginnersCourseCard(props) {

    const [data, setData] = useState(props.courseInfo);
    const [status, setStatus] = useState(props.status);
    const [CourseStartDate, setCourseStartDate] = useState();
    const { currentUser } = useAuth();
    const [loading, setLoading] = useState(true)
    const navigate = useNavigate();
    let user = currentUser.uid;

    useEffect(() => {
        const qLessons = query(collection(db, 'courses', 'JwwXQV40eM2NUXSTOoVx', 'programs', data.id, 'sessions'), orderBy('Lesson'), limit(1))
        getDocs(qLessons)
        .then((docs) => {
            docs.forEach((doc) => {
                setCourseStartDate(getDateInfo(doc.data().unixTimeDateStamp));
            })
        })
        .then(() => {
            setLoading(false)
        })
    }, [])

    // let CourseStartDate = getDateInfo(data.data().startDateUnix);
    
    function goToBeginnersCourse() {
        // props.setUrlParam(data.id)
        navigate(`/admin/livebeginnerscourse/${data.id}`)
    }

    if (loading) {
        return <></>
    }

    return (
        <div onClick={goToBeginnersCourse.bind()} className={"live-beginners-course-card-wrapper " + status}>
            <div className="live-beginners-course-card-timestamp-wrapper">
                <h4>{CourseStartDate.Month + ' ' + CourseStartDate.year}</h4>
                <p>{CourseStartDate.day + '/' + CourseStartDate.month + '/' + CourseStartDate.year}</p>
            </div>
            <div className={"live-beginners-course-card-status-label " + status}>
                <p>{status}</p>
            </div>
        </div>
    );
}
