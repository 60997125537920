import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { httpsCallable } from "firebase/functions";
import { functions } from '../../firebase';
import { useAuth } from '../../contexts/AuthContext';
import settings from '../../images/settings.svg';
import logoutImg from '../../images/logout.svg';

export default function SideMenu(props) {

    const [logOutError, setLogOutError] = useState('');
    const { logout, currentUser } = useAuth();
    const navigate = useNavigate();
    const [adminStatus, setAdminStatus] = useState('');
    const [menuOpen, setMenuOpen] = useState(false);

    useEffect(() => {
        
        if (
            currentUser.uid != 'qK3CWSEYeDU5261DvUO8PkUC4d33' &&
            currentUser.uid != 'vIQtkcK3R8NPKOKGMquYYcS0BPB3'
        ) { setAdminStatus('none') }

    }, [])


    function checkForActiveLink(link) {
        if (link === props.currentTab) return 'active';
    }

    function handleLogout() {
        setLogOutError('');

        logout()
        .then(() => {
            navigate('/login');
        })
    }

    function handleSettings() {

        let functionRef = httpsCallable(functions, 'ext-firestore-stripe-payments-createPortalLink')
        functionRef({ returnUrl: window.location.href })
        .then((data) => {
            window.location.assign(data.data.url);
        })
    }

    function handleToggleMenu(e) {
        setMenuOpen(!menuOpen);
    }



    return (
        <>
        <div className={`side-menu ${menuOpen ? 'opened' : 'closed' }`}>
            <div onClick={e => handleToggleMenu(e)} className="hamburger">☰</div>
            <div className="menu-collection">
            <h2 className="logo">WowByAnn</h2>
                <Link to='/' style={{ color: 'var(--muted)', textDecoration: 'none' }} >
                <div className={'menu-link-wrapper ' + checkForActiveLink("courses")}>
                    <p className="menu-link-text">Courses</p>
                </div>
                </Link>
                <Link to='/beginners-course' style={{ color: 'var(--muted)', textDecoration: 'none' }} >
                <div className={'menu-link-wrapper ' + checkForActiveLink("beginners-course")}>
                    <p className="menu-link-text">Beginners Course</p>
                </div>
                </Link>
            </div>
            <div className="menu-collection">
                
                <Link to='/admin' style={{ color: 'var(--muted)', textDecoration: 'none', display: adminStatus }} >
                    <div className={'menu-link-wrapper ' + checkForActiveLink("admin")}>
                        <p className="menu-link-text">Admin</p>
                    </div>
                </Link>

                <div onClick={handleSettings} className={'menu-link-wrapper ' + checkForActiveLink("settings")}>
                    <img src={settings} alt="" className="menu-link-icon" />
                    <p className="menu-link-text">Settings</p>
                </div>
                <div onClick={handleLogout} className="menu-link-wrapper">
                    <img src={logoutImg} alt="" className="menu-link-icon" />
                    <p className="menu-link-text">Logout</p>
                    {logOutError && <div className="error">{logOutError}</div>}
                </div>
            </div>
        </div>
        <div onClick={e => handleToggleMenu(e)} className={`side-menu-overlay ${menuOpen ? 'opened' : 'closed' }`}></div>
        </>
    )
}
