import React from 'react';
import { useNavigate } from 'react-router-dom';
import downloadIcon from '../../../images/download.svg';

export default function BeginnersCourseLivePast() {
  
  const navigate = useNavigate();
  
  
  function handleChangeRoute() {
    navigate('/')
  }
  
  
  
  
  return (
    <>
        <a target="_blank" className="file-download-card-wrapper" download="proposed_file_name" href='https://cdn.wowbyann.com/files/Your%20Healthy%20DRESS%20for%20Success.pdf'>
            <img src={downloadIcon} alt="" className="download-icon" />
            <h3 className="file-download-text">
            Download Nutrition- cheat sheet
            </h3>
        </a>
        <h4 onClick={handleChangeRoute.bind()} style={{ cursor: "pointer" }}>You completed the beginners course. You are now ready to follow along with the group sessions.</h4>
        {/* <div className="dashboard-section-wrapper">
            <h2 className="dashboard-section-title">
            Course Progress
            </h2>
            <div className="beginners-live-progress-wrapper">
                <div className="progress-bar-wrapper">
                    <div className="progress-outer-circle"></div>
                    <div className={`progress-inner-circle progress-inner-circle-${rotationIndex}`} style={{ transform: `rotate(${degrees}deg)` }}></div>
                    <div className="progress-bar-overlay"></div>
                </div>
                <div className="progress-bar-text-wrapper">
                    <h2 className="progress-bar-title">{latestCourseData}/16 session done</h2>
                    <p className="progress-bar-subtitle">24/50 hours watched</p>
                </div>
            </div>
            <div className="beginners-live-sessions-cards-wrapper">
            <div className="beginners-course-card">
                <div className="beginners-course-card-content">
                    <h2 className="beginners-course-card-title">
                    Next online session:
                    </h2>
                    <div className="beginners-course-card-description">

                    {nextCourseDate.month}/{nextCourseDate.day}/{nextCourseDate.year} ➞ {nextCourseDate.hours}:{nextCourseDate.minutes}h
                    </div>
                    <h2 className="beginners-course-card-title">
                    Focus:
                    </h2>
                    <div className="beginners-course-card-description">
                    {nextCourseData.Focus}
                    </div>
                </div>
            </div>
            <a id="beginnersCoursePreviousSession" href="#" onClick={handleStartVideo} className="beginners-course-card" style={{ textDecoration: "none" }}>
                <div className="beginners-course-previous-session-overlay">
                </div>
                <img src={playButton} alt="" className="play-button-image" />
                <h2 className="beginners-course-card-title">
                    Re-watch the<br/>previous session
                </h2>
            <iframe ref={videoRef} className="youtube-video-embed" src={`https://www.youtube.com/embed/${videoUrlId}?modestbranding=0`} title="YouTube video player" 
            frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen>
            </iframe>
            </a>
            </div>
        </div> */}
    </>
);
}
