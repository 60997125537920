import React from 'react';

export default function SkeletonCourseCard() {
    
    return (
        <div className="course-card skeleton-invis">
            <div className="course-card-left skeleton"></div>
            <div className="course-card-content">
                <div className="course-card-text-content">
                    <h2 className="course-card-title skeleton">
                    </h2>
                    <div className="course-card-description skeleton">
                    </div>
                </div>
                <div className="course-card-footer-content">
                    <button className="course-card-button skeleton">
                    </button>
                    <a href="" className="course-card-more-info skeleton">
                    </a>
                </div>
            </div>
        </div>
    );
}
