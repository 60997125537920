import React, { useRef, useState } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { Link, useNavigate } from 'react-router-dom';

export default function Login() {

    const emailRef = useRef();
    const passwordRef = useRef();
    const { login } = useAuth();
    const [error, setError] = useState('')
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    function handleSubmit(e) {
        e.preventDefault();

        setError('');
        setLoading(true);

        login(emailRef.current.value, passwordRef.current.value)
        .then((userCredential) => {
            navigate('/');
        })
        .catch((error) => {
            setError('Failed to sign in');
            console.error(error);
        }) 
        setLoading(false);

    }

    return (
        <div className="auth-page-wrapper">
        <div className="sign-up-card">
            <h2 className="auth-header">Log in</h2>
            {error && <div className="error">{error}</div>}
            <form onSubmit={handleSubmit} className="auth-form sign-up-form">
                <div className="auth-form-group">
                    <label htmlFor="" className="auth-form-label">Email</label>
                    <input type="email" ref={emailRef} className="auth-form-input" required/>
                </div>
                <div className="auth-form-group">
                    <label htmlFor="" className="auth-form-label">Password</label>
                    <input type="password" ref={passwordRef} className="auth-form-input" required/>
                </div>
                <button disabled={loading} type="submit" className="button-stretch">Log in</button>
            </form>
        </div>
        <div className="switch-auth-domain">
            Don't have an account yet? <Link style={{ color: 'var(--primary-clr)' }} to='/signup'>Create account</Link>
        </div>
        </div>
    )
}
