import React, { useState, useEffect } from 'react';
import SideMenu from '../SideMenu';
import CourseCard from '../courses/CourseCard';
import { useAuth } from '../../../contexts/AuthContext';
import { collection, getDoc, getDocs, doc, query, where, limit } from 'firebase/firestore';
import { db } from '../../../firebase';
import downloadIcon from '../../../images/download.svg';
import SkeletonCourseCard from '../courses/SkeletonCourseCard';
import playButton from '../../../images/play-button.png';

export default function BeginnersCourseRecorded() {

  let currentDate = Date.now();
  let date = new Date(currentDate * 1000);

  const { currentUser } = useAuth();
  let user = currentUser.uid;
  const [loading, setLoading] = useState(true);
  const [personalDataLoading, setPersonalDataLoading] = useState(true);
  const [activeCourse, setActiveCourse] = useState(false);
  const [courseCardData, setCourseCardData] = useState([]);
  const [activeCourseData, setActiveCourseData] = useState();
  const [activeCourseUserData, setActiveCourseUserData] = useState();
  const [recordedBeginnersCourseForSale, setRecordedBeginnersCourseForSale] = useState(false);


  // Check if course is active
  useEffect(() => {
    const productRef = doc(db, 'products', 'prod_L1RzGegkG4WXQJ')
    const q = query(collection(db, 'users', user, 'subscriptions'), where('product', '==', productRef));
    getDocs(q)
    .then((docs) => {
      if (docs.empty) {
        getDoc(doc(db, 'courses', 'X1xtcsGw0w06UVQHdHiH'))
        .then((fileX) => {
          if (fileX.data().Published == true) {
            setCourseCardData({
              doc: fileX,
              stripePriceTag: fileX.data().stripePriceTag
            });
            setRecordedBeginnersCourseForSale(true);
          }
          setLoading(false);
        })
      }

      else {
        docs.forEach((file) => {
          if (file.data().status == 'active') {
            getDoc(doc(db, 'courses', 'X1xtcsGw0w06UVQHdHiH'))
            .then((doc) => {
              setActiveCourseData(doc);
              setActiveCourse(true);
              setLoading(false);
            })
          }
          else {
            getDoc(doc(db, 'courses', 'X1xtcsGw0w06UVQHdHiH'))
            .then((doc) => {
              setCourseCardData({
                doc: doc,
                stripePriceTag: doc.data().stripePriceTag
              });
              setLoading(false);
            })
          }
        })
      }
    })
  }, [])

  // Effect to calculate rotation index
  useEffect(() => {
    getDoc(doc(db, 'users', user, 'courses', 'X1xtcsGw0w06UVQHdHiH'))
    .then((doc) => {
      setActiveCourseUserData(doc);
    })
    setPersonalDataLoading(false);
  }, [])



  if (loading) {
    return <></>
  }

  else if (!recordedBeginnersCourseForSale) {
    return <h4>There currently isn't a course you can buy.</h4>
  }

  else if (activeCourse == false) {    
    return (
         <CourseCard cardInfo={courseCardData}/>
    )
  }

  else if (personalDataLoading == true) {
    return (
      <>
        <a target="_blank" className="file-download-card-wrapper" download="proposed_file_name" href='https://firebasestorage.googleapis.com/v0/b/wow-by-ann.appspot.com/o/InteractiveNFL_WhatsOnTheNFL_March2020.pdf?alt=media&token=fc218750-676d-4d91-8c86-e1b3cc879c21'>
            <img src={downloadIcon} alt="" className="download-icon" />
            <h3 className="file-download-text">
            Download Nutrition- cheat sheet
            </h3>
        </a>
      </>
    )
  }

  console.log(activeCourseData.data())
  console.log(activeCourseUserData.data())

  return (
    <>
      <a target="_blank" className="file-download-card-wrapper" download="proposed_file_name" href='https://firebasestorage.googleapis.com/v0/b/wow-by-ann.appspot.com/o/InteractiveNFL_WhatsOnTheNFL_March2020.pdf?alt=media&token=fc218750-676d-4d91-8c86-e1b3cc879c21'>
          <img src={downloadIcon} alt="" className="download-icon" />
          <h3 className="file-download-text">
          Download Nutrition- cheat sheet
          </h3>
      </a>
      <div className="dashboard-section-wrapper">
        <h2 className="dashboard-section-titl">
          Course Progress
        </h2>
      </div>
    </>
  );
}
