import { doc, getDoc, updateDoc } from 'firebase/firestore';
import React, { useState, useEffect, useRef } from 'react';
import { db } from '../../../../firebase';
import DropdownIcon from '../../../../images/dropdown-icon.svg';
import { getDateInfo, getUnixTimeStamp, fromDateToUnix } from '../../../utility/timestamp';

export default function LiveBeginnersCourseLessonCard(props) {
    
    const [data, setData] = useState(props.doc.data());
    const [id, setId] = useState(props.doc.id)
    const [dropDownActive, setDropDownActive] = useState(false);
    const [loading, setLoading] = useState(false);
    const [updates, setUpdates] = useState(false);

    const dateRef = useRef();
    const timeRef = useRef();
    const focusRef = useRef();
    const lessonUrlRef = useRef();
    
    // console.log(props.doc)
    useEffect(() => {
        setData(props.doc.data())
        setId(props.doc.id)
    }, [props.doc])


    useEffect(() => {

        let dateInfo = getDateInfo(data.unixTimeDateStamp);
        let defaultDate = `${dateInfo.year}-${dateInfo.month}-${dateInfo.day}`;
        let defaultTime = `${dateInfo.hours}:${dateInfo.minutes}`

        focusRef.current.value = data.Focus
        timeRef.current.value = defaultTime
        dateRef.current.value = defaultDate
        lessonUrlRef.current.value = data.LessonUrl

    }, [data])


    function handleDropdownClick() {
        if (dropDownActive == true) {
            setDropDownActive(false)
        } else { setDropDownActive(true) }
    }

    function handleSubmit(e) {
        e.preventDefault();
        
        
        updateDoc(doc(db, 'courses', 'JwwXQV40eM2NUXSTOoVx', 'programs', props.session, 'sessions', id), {
            Focus: focusRef.current.value,
            LessonUrl: lessonUrlRef.current.value,
            unixTimeDateStamp: fromDateToUnix(dateRef.current.value, timeRef.current.value)
        })
        .then(() => {
            setUpdates(false);
        })
    }

    function handleMarkAsComplete() {
        setLoading(true);

        updateDoc(doc(db, 'courses', 'JwwXQV40eM2NUXSTOoVx', 'programs', props.session, 'sessions', id), {
            Complete: true
        })
    }

    let complete = false;
    if (data.Complete == true) {
        complete = true;
    }
    
    let dateInfo = getDateInfo(data.unixTimeDateStamp);
    let defaultDate = `${dateInfo.year}-${dateInfo.month}-${dateInfo.day}`;
    let defaultTime = `${dateInfo.hours}:${dateInfo.minutes}`

    return (
        <div className="live-beginners-course-lesson-card">
            <div onClick={handleDropdownClick.bind()} className="live-beginners-course-lesson-card-header-container">
                <img src={DropdownIcon} alt="" className={"drop-down-icon " + (dropDownActive ? 'active' : '')} />
                <div className="live-beginners-course-title-wrapper">
                    <h2>{dateInfo.Month + ' ' + dateInfo.day + ": Session " + data.Lesson}</h2>
                    <p className="live-beginners-course-lesson-card-session-complete" style={{ display: complete ? 'block' : 'none' }}>Complete</p>
                </div>
            </div>
            <div className={"live-beginners-course-lesson-card-dropdown-wrapper " + (dropDownActive ? 'active' : '')}>
                <form onSubmit={handleSubmit} >
                    <div className="auth-form-group">
                        <label htmlFor="" className="auth-form-label">Focus:</label>
                        <textarea onChange={() => setUpdates(true)} ref={focusRef} type="text" className="admin-form-input" defaultValue={data.Focus} />
                    </div>
                    <div className="auth-form-group">
                        <label htmlFor="" className="auth-form-label">Date:</label>
                        <input onChange={() => setUpdates(true)} ref={dateRef} type="date" className="admin-form-input" defaultValue={defaultDate} />
                        <input onChange={() => setUpdates(true)} ref={timeRef} type="time" className="admin-form-input" defaultValue={defaultTime} />
                    </div>
                    <div className="auth-form-group">
                        <label htmlFor="" className="auth-form-label">Lesson Url:</label>
                        <input onChange={() => setUpdates(true)} ref={lessonUrlRef} type="text" className="admin-form-input" defaultValue={data.LessonUrl} />
                    </div>
                    <button type="submit" disabled={!updates} className="button-stretch">Update Data</button>
                    <button style={{ display: complete ? 'none' : 'inline-block' }} onClick={handleMarkAsComplete} disabled={loading} type="button" className="button-stretch button-outline">Mark Session as Complete</button>
                </form>
            </div>
        </div>
    );
}
