import React, { useState, useEffect } from 'react';
import CourseCard from './courses/CourseCard';
import SideMenu from '../../app/SideMenu';
import Console from '../Console';
import { useAuth } from '../../../contexts/AuthContext';
import { collection, getDoc, getDocs, doc } from 'firebase/firestore';
import { db } from '../../../firebase';


function Courses() {

    const { currentUser } = useAuth();
    let user = currentUser.uid;
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    let dataArray = [];


    useEffect(() => {
        getDocs(collection(db, 'courses'))
        .then((docs) => {
          docs.forEach((doc) => {
            dataArray.push(doc)
          })
          setData(dataArray)
        })
        .catch(error => {
          console.error("Error fetching data: ", error);
          setError(error);
        })
        .finally(() => {
          setLoading(false);
        })
    }, [])

    if (loading) return (
      <div className="dashboard-wrapper">
            <SideMenu currentTab='admin' />
            <div className="main-content">
                <Console currentTab="courses" />
            </div>
        </div>
    )

    return (
      <div className="dashboard-wrapper">
            <SideMenu currentTab='admin' />
            <div className="main-content">
                <Console currentTab="courses" />


                <div className="dashboard-section-wrapper">
                    <h2 className="dashboard-section-title">All Courses</h2>
                    <div className="courses-wrapper">
                    {data.map((course, i) => {
                        return (<CourseCard cardInfo={course} key={i} />)
                      })}
                    </div>
                </div>


            </div>
        </div>
    )
}

export default Courses;