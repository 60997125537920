import React, { useState, useEffect } from 'react';
import SideMenu from './SideMenu';
import CourseCard from './courses/CourseCard';
import SkeletonCourseCard from './courses/SkeletonCourseCard';
import { useAuth } from '../../contexts/AuthContext';
import { collection, getDoc, getDocs, doc, query, where, limit, orderBy, startAt } from 'firebase/firestore';
import { db } from '../../firebase';


function Courses() {

  const { currentUser } = useAuth();
  let user = currentUser.uid;

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [name, setName] = useState('');
  let dataArray = [];

  // Getting all Courses
  useEffect(() => {
    return
    getDocs(query(collection(db, 'courses'), where('getStripeTag', '==', true), where("Published", "==", true)))
    .then((docs) => {
      docs.forEach((doc) => {
        dataArray = data;
        dataArray.push({
          doc: doc,
          stripePriceTag: doc.data().stripePriceTag
        })
        setData(dataArray)
      })
      // setData(dataArray)
    })
    .catch(error => {
      console.error("Error fetching data: ", error);
      setError(error);
    })
    .finally(() => {
      setLoading(true);
    })
  }, [])

  // Setting the username on the page
  useEffect(() => {
    getDoc(doc(db, 'users', user))
    .then((doc) => {
      setName(doc.data().Name)
    })
  }, [])

  // Getting the recorded beginners course
  useEffect(() => {

    const pricesRefs = [];
    getDocs(collection(db, 'products', 'prod_L1RzGegkG4WXQJ', 'prices'))
    .then((prices) => {
      prices.forEach((price) => {
        pricesRefs.push(doc(db, 'products', 'prod_L1RzGegkG4WXQJ', 'prices', price.id));
      })
      console.log(pricesRefs)
    })
    .then(() => {
      const q = query(collection(db, 'users', user, 'payments'), where('prices', 'array-contains-any', pricesRefs), where('status', '==', 'succeeded'));
      getDocs(q)
      .then((docs) => {
        if (docs.empty) {
          getDoc(doc(db, "courses/X1xtcsGw0w06UVQHdHiH"))
          .then((file) => {
            if (file.data().Published) {
              dataArray = data;
              dataArray.push({
                doc: file,
                stripePriceTag: file.data().stripePriceTag
              })
            }
          })
        }
        docs.forEach((doc) => {
        })
      })
    })

  }, [])

  // Getting the live beginners course
  useEffect(() => {
    
    const cursor = 1
    const productRef = doc(db, 'products', 'prod_L1RwA6WubReSjN')
    const q = query(collection(db, 'users', user, 'payments'), orderBy('amount_received'), where('amount_received', '>', 0), limit(100), startAt(cursor));
    getDocs(q)
    .then((docs) => {
      if (docs.empty) {
        getDoc(doc(db, "courses/JwwXQV40eM2NUXSTOoVx"))
        .then((file) => {
          if (file.data().Published) {
            
            getDocs(query(collection(db, 'courses', 'JwwXQV40eM2NUXSTOoVx', 'programs'), where('Status', '==', 'future'), limit(1)))
            .then((files) => {
              files.forEach((file) => {
                if (file.data().stripePriceTag) {
                  getDoc(doc(db, 'courses', 'JwwXQV40eM2NUXSTOoVx'))
                  .then((document) => {
                    dataArray = data;
                    dataArray.push({
                      doc: document,
                      stripePriceTag: file.data().stripePriceTag
                    })
                    setData(dataArray);
                  })
                  .catch(error => {
                    console.error("Error fetching data: ", error);
                    setError(error);
                  })
                  .finally(() => {
                    setLoading(false);
                  })
                }
                else { setLoading(false) }
              })
            })

          } else { setLoading(false) }
        })
      }
      else {
        setLoading(false)
      }
    })
    
  }, [])


  if (loading) return (
    <div className="dashboard-wrapper">
        <SideMenu currentTab='courses' />
        <div className="main-content">
          <div className="courses-welcome-wrapper">
              <h1 className="courses-welcome-title">
                Hello, <span>{name}</span>
              </h1>
              <p className="courses-welcome-text muted">
              Continue one of your programs or sign up for a new one.
              </p>
            </div>
            <div className="dashboard-section-wrapper">
              <h2 className="dashboard-section-title">
                All courses
              </h2>
              <div className="courses-wrapper">
                <SkeletonCourseCard />
                <SkeletonCourseCard />
                <SkeletonCourseCard />
            </div>
          </div>
        </div>
    </div>
  );

  // console.log(data);
  return (
    <div className="dashboard-wrapper">
        <SideMenu currentTab='courses' />
        <div className="main-content">
          <div className="courses-welcome-wrapper">
            <h1 className="courses-welcome-title">
              Hello, <span>{name}</span>
            </h1>
            <p className="courses-welcome-text muted">
            Continue one of your programs or sign up for a new one.
            </p>
          </div>
          <div className="dashboard-section-wrapper">
            <h2 className="dashboard-section-title">
              All courses
            </h2>
            <div className="courses-wrapper">
              {data.map((course, i) => {
                return (<CourseCard cardInfo={course} key={i} />)
              })}
            </div>
          </div>
        </div>
    </div>
  );
}

export default Courses;