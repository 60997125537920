import { collection, getDocs, limit, orderBy, query, where } from 'firebase/firestore';
import React, { useEffect, useState } from 'react';
import { db } from '../../../firebase';
import { getDateInfo } from '../../utility/timestamp';

export default function BeginnersCourseLiveFuture(props) {
  
  const [date, setDate] = useState()
  const [loading, setLoading] = useState(true)
  
  useEffect( async () => {
    let latestSessionDocs = await getDocs(query(collection(db, `courses/JwwXQV40eM2NUXSTOoVx/programs/${props.data.id}/sessions`), limit(1), where('Lesson', '==', 1)))
    latestSessionDocs.forEach((latestSessionDoc) => {
      setDate(getDateInfo(latestSessionDoc.data().unixTimeDateStamp))
      setLoading(false)
    })
  }, [])
  
  if (loading) {
    return (
      <>
      <h1>You purchased the Live Beginners Course.</h1>
      <h2 style={{ color: 'var(--muted)', fontWeight: '600' }}>Your course starts on:</h2>
      </>
    )
  }
  return (
    <>
    <h1>You purchased the Live Beginners Course.</h1>
    <h2 style={{ color: 'var(--muted)', fontWeight: '600' }}>Your course starts on:  {date.Month} {date.day} {date.year}</h2>
    
    {/* free session, remove code below after free session */}
    <div className="beginners-live-sessions-cards-wrapper free-session-wrapper">
        <h2>Join the free session</h2>
        <a id="beginnersCoursePreviousSessions" href="#" className="beginners-course-card" style={{ textDecoration: "none", position: 'relative' }}>
            <iframe style={{ display: 'flex' }} className="youtube-video-embed" src={`https://www.youtube.com/embed/AE40eMBmTzM?modestbranding=0`} title="YouTube video player"
            frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen>
            </iframe>
        </a>
    </div>
    
    </>
  );
}
