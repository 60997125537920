import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import SideMenu from '../app/SideMenu';
import Courses from './tabs/Courses';
import LiveBeginnersCourse from './tabs/LiveBeginnersCourse';

export default function Console(props) {

    const navigate = useNavigate();
    function handleNavigate() {
        return
        navigate('/admin/livebeginnerscourse')
        window.location.reload(false)
    }

    function checkForActiveLink(link) {
        if (link === props.currentTab) return 'active';
    }

    
    return (
        <>
            <div className="tab-links-wrapper">
            <Link to='/admin/courses' style={{ color: 'var(--muted)', textDecoration: 'none' }} >
            <div className={ 'tab-link-wrapper ' + checkForActiveLink("courses")}>
                <h4 className="tab-link-text">
                Courses
                </h4>
                <div className="tab-link-underline">
                </div>
            </div>
            </Link>
            <Link to='/admin/livebeginnerscourse' onClick={handleNavigate} style={{ color: 'var(--muted)', textDecoration: 'none' }} >
            <div className={ 'tab-link-wrapper ' + checkForActiveLink("livebeginnerscourse")}>
                <h4 className="tab-link-text">
                Beginners Course (live)
                </h4>
                <div className="tab-link-underline">
                </div>
            </div>
            </Link>
            <Link to='/admin/recordedbeginnerscourse' onClick={handleNavigate} style={{ color: 'var(--muted)', textDecoration: 'none' }} >
            <div className={ 'tab-link-wrapper ' + checkForActiveLink("recordedbeginnerscourse")}>
                <h4 className="tab-link-text">
                Beginners Course (recorded)
                </h4>
                <div className="tab-link-underline">
                </div>
            </div>
            </Link>
            </div>
        </>
    )
}
