import React, { useState, useEffect } from 'react';
import SideMenu from '../SideMenu';
import CourseCard from '../courses/CourseCard';
import BeginnersCourseLiveActive from './BeginnersCourseLiveActive';
import BeginnersCourseLivePast from './BeginnersCourseLivePast';
import BeginnersCourseLiveFuture from './BeginnersCourseLiveFuture';
import { useAuth } from '../../../contexts/AuthContext';
import { collection, getDoc, getDocs, doc, query, where, limit, startAt, orderBy } from 'firebase/firestore';
import { db } from '../../../firebase';
import downloadIcon from '../../../images/download.svg';
import SkeletonCourseCard from '../courses/SkeletonCourseCard';
import playButton from '../../../images/play-button.png';



export default function BeginnersCourseLive() {

    let currentDate = Date.now();
    let date = new Date(currentDate * 1000);


    const { currentUser } = useAuth();
    let user = currentUser.uid;
    const [loading, setLoading] = useState(true);
    const [activeCourse, setActiveCourse] = useState(false);
    const [courseCardData, setCourseCardData] = useState([]);
    const [activeCourseData, setActiveCourseData] = useState();
    const [liveBeginnersCourseForSale, setLiveBeginnersCourseForSale] = useState(false);



    useEffect( async () => {
      const cursor = 1
      const productRef = doc(db, 'products', 'prod_L1RwA6WubReSjN')
      const q = query(collection(db, 'users', user, 'payments'), orderBy('amount_received'), where('amount_received', '>', 0), limit(100), startAt(cursor))
      const docs = await getDocs(q)
      if (docs.empty) {
        const courseDoc = await getDoc(doc(db, 'courses', 'JwwXQV40eM2NUXSTOoVx'))
        if (courseDoc.data().Published == true) {
          setCourseCardData({
            doc: courseDoc,
            stripePriceTag: courseDoc.data().stripePriceTag
          });
          setLiveBeginnersCourseForSale(true);
          setLoading(false)
        }
      }
      else {
        docs.forEach( async (file) => {
          if (file.data().amount_received == file.data().amount && file.data().items[0].price.product == 'prod_L1RwA6WubReSjN') {
            const price = file.data().items[0].price.id;
            const courseDocs = await getDocs(query(collection(db, 'courses/JwwXQV40eM2NUXSTOoVx/programs'), where('stripePriceTag', '==', price), limit(1)))
            courseDocs.forEach((courseDoc) => {
              setActiveCourseData(courseDoc);
              setActiveCourse(true);
              setLoading(false);
            })
          }
        })
      }
    }, [])



    if (loading) {
        return <></>
    }

    else if (activeCourse == false) {
      if (!liveBeginnersCourseForSale) {
        return <h4>There currently isn't a course you can buy.</h4>
      }
      return (
        <>
          <CourseCard cardInfo={courseCardData}/>

          {/* free session, remove code below after free session */}
          <div className="beginners-live-sessions-cards-wrapper free-session-wrapper">
            <h2>Join the free session</h2>
            <a id="beginnersCoursePreviousSessions" href="#" className="beginners-course-card" style={{ textDecoration: "none", position: 'relative' }}>
                
                <iframe style={{ display: 'flex' }} className="youtube-video-embed" src={`https://www.youtube.com/embed/AE40eMBmTzM?modestbranding=0`} title="YouTube video player"
                frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen>
                </iframe>
            </a>
          </div>

        </>
      )
    }
    
    else {

      if (activeCourseData.data().Status == "future") {
        return <BeginnersCourseLiveFuture data={activeCourseData} />
      }

      else if (activeCourseData.data().Status == "past") {
        return <BeginnersCourseLivePast data={activeCourseData} />
      }

      else if (activeCourseData.data().Status == "active") {
        return <BeginnersCourseLiveActive data={activeCourseData} />
      }
    }
}
