import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import LiveBeginnersCourseCard from './livebeginnerscourse/LiveBeginnersCourseCard';
import Console from '../Console';
import SideMenu from '../../app/SideMenu';
import LiveBeginnersCourseSessions from './livebeginnerscourse/LiveBeginnersCourseSessions';
import { useAuth } from '../../../contexts/AuthContext';
import { collection, getDocs, query, where, limit } from 'firebase/firestore';
import { db } from '../../../firebase';
import { getUrlParams } from '../../utility/urlparams';


function LiveBeginnersCourse() {

    const { currentUser } = useAuth();
    let user = currentUser.uid;
    const [activeData, setActiveData] = useState([]);
    const [futureData, setFutureData] = useState([]);
    const [pastData, setPastData] = useState([]);
    const { id: urlParam } = useParams();
    // const [urlParam, setUrlParam] = useState(getUrlParams().key);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);


    let activeDataArray = [];
    let futureDataArray = [];
    let pastDataArray = [];

    // useEffect(() => {
    //     setUrlParam(getUrlParams().key);
    // }, [getUrlParams().key])
    
    useEffect(() => {

        const activeQ = query(collection(db, 'courses', 'JwwXQV40eM2NUXSTOoVx', 'programs'), where('Status', '==', 'active'));
        getDocs(activeQ)
        .then((docs) => {
            docs.forEach((doc) => {
                activeDataArray.push(doc)
            })
            setActiveData(activeDataArray);
        })
        .catch((error) => {
            console.error("Error fetching data: ", error);
            setError(error);
        })
        .finally(() => {
            setLoading(false);
        })
        
        
    }, [urlParam])

    useEffect(() => {

        const futureQ = query(collection(db, 'courses', 'JwwXQV40eM2NUXSTOoVx', 'programs'), where('Status', '==', 'future'));
        getDocs(futureQ)
        .then((docs) => {
            docs.forEach((doc) => {
                futureDataArray.push(doc)
            })
            setFutureData(futureDataArray);
        })
        .catch((error) => {
            console.error("Error fetching data: ", error);
            setError(error);
        })
        .finally(() => {
            setLoading(false);
        })
        
        
    }, [urlParam])

    useEffect(() => {

        const pastQ = query(collection(db, 'courses', 'JwwXQV40eM2NUXSTOoVx', 'programs'), where('Status', '==', 'past'), limit(5));
        getDocs(pastQ)
        .then((docs) => {
            docs.forEach((doc) => {
                pastDataArray.push(doc)
            })
            setPastData(pastDataArray);
        })
        .catch((error) => {
            console.error("Error fetching data: ", error);
            setError(error);
        })
        .finally(() => {
            setLoading(false);
        })
        
        
    }, [urlParam])
    
    if (loading) return (
        <div className="dashboard-wrapper">
            <SideMenu currentTab='admin' />
            <div className="main-content">
                <Console currentTab="livebeginnerscourse" />
            </div>
        </div>
    )

    if (!urlParam) return (
        <div className="dashboard-wrapper">
            <SideMenu currentTab='admin' />
            <div className="main-content">
                <Console currentTab="livebeginnerscourse" />


                <div className="dashboard-section-wrapper">
                    <h2 className="dashboard-section-title">Beginner Courses</h2>
                    <div className="live-beginners-course-cards">
                        {futureData.map((livecourse, i) => {
                            return (<LiveBeginnersCourseCard key={i} courseInfo={livecourse} status={'future'} />)
                        })}
                        {activeData.map((livecourse, i) => {
                            return (<LiveBeginnersCourseCard key={i} courseInfo={livecourse} status={'active'} />)
                        })}
                        {pastData.map((livecourse, i) => {
                            return (<LiveBeginnersCourseCard key={i} courseInfo={livecourse} status={'past'} />)
                        })}
                    </div>
                </div>


            </div>
        </div>
    )

    return (
        <div className="dashboard-wrapper">
            <SideMenu currentTab='admin' />
            <div className="main-content">
                <Console currentTab="livebeginnerscourse" />


                <div className="dashboard-section-wrapper">
                    <LiveBeginnersCourseSessions sessionId={urlParam} />
                </div>


            </div>
        </div>
    )
}

export default LiveBeginnersCourse;