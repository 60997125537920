import React, { useState, useRef } from 'react';
import { db } from '../../../../firebase';
import { useAuth } from '../../../../contexts/AuthContext';
import { setDoc, doc, addDoc, getDoc, collection, onSnapshot, updateDoc } from 'firebase/firestore';

export default function CourseCard(props) {

    const [data, setData] = useState(props.cardInfo.data());
    const [mode, setMode] = useState(data.productType);
    const { currentUser } = useAuth();
    const [loading, setLoading] = useState()
    let user = currentUser.uid;

    const titleRef = useRef();
    const descriptionRef = useRef();
    const buttonRef = useRef();
    const publishedRef = useRef();

    let buttonText = '';
    if (false) {
        buttonText = 'Go to course';
    }
    else {
        buttonText = data.Button;
    }

    function onSave() {
        console.log(publishedRef.current.checked)
        updateDoc(doc(db, "courses", props.cardInfo.id), {
            Title: titleRef.current.innerText,
            Description: descriptionRef.current.innerText,
            Button: buttonRef.current.innerText,
            Published: publishedRef.current.checked
        })
    }
    
    return (
        <div className="course-card">
                <div className="course-card-left" style={{ backgroundImage: `url(${data.Cover})` }}></div>
                <div className="course-card-content">
                    <div className="course-card-text-content">
                        <h2 className="course-card-title" ref={titleRef} contentEditable suppressContentEditableWarning>
                            {data.Title}
                        </h2>
                        <div className="course-card-description" ref={descriptionRef} contentEditable suppressContentEditableWarning>
                            {data.Description}
                        </div>
                    </div>
                    <div className="course-card-footer-content">
                        <div className="course-card-button" ref={buttonRef} contentEditable suppressContentEditableWarning>
                            {loading ? <div className="loader"></div> : buttonText}
                        </div>
                        
                    </div>
                </div>
            <div className="admin-actions-wrapper">
            <label className="switch">
                {data.Published ? 
                    <input ref={publishedRef} type="checkbox" defaultChecked ></input> :
                    <input ref={publishedRef} type="checkbox"></input>
                }
                <span className="slider round"></span>
            </label>
                <button className="button" onClick={onSave}>save</button>
            </div>
        </div>
    );
}
