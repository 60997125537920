import React, { useRef, useState } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { Link, useNavigate } from 'react-router-dom';
import { db } from '../../firebase';
import { setDoc, doc, addDoc, getDoc } from 'firebase/firestore';

export default function Signup() {

    const emailRef = useRef();
    const passwordRef = useRef();
    const passwordConfirmRef = useRef();
    const nameRef = useRef();
    const { signup } = useAuth();
    const [error, setError] = useState('')
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    function handleSubmit(e) {
        e.preventDefault();

        if (passwordRef.current.value !== passwordConfirmRef.current.value) {
            return setError('Passwords do not match')
        }

        setError('');
        setLoading(true);

        let user = ''
        signup(emailRef.current.value, passwordRef.current.value)
        .then((userCredentials) => {
            user = userCredentials.user;
            setDoc(doc(db, 'users', user.uid), {
                Email: emailRef.current.value,
                Name: nameRef.current.value,
            })
        })
        .then(() => {
            setDoc(doc(db, 'users', user.uid, 'courses', 'X1xtcsGw0w06UVQHdHiH'), {
                Title: "Beginners Training (recorded)",
                Progress: {
                    "1": false,
                    "2": false,
                    "3": false,
                    "4": false,
                    "5": false,
                    "6": false,
                    "7": false,
                    "8": false,
                    "9": false,
                    "10": false,
                    "11": false,
                    "12": false,
                    "13": false,
                    "14": false,
                    "15": false,
                    "16": false
                }
            })
        })
        .then(() => {
            navigate('/');  
        })
        .catch(error => {
            setError('Failed to create an account, ' + error.message)
        })
        setLoading(false);

    }

    return (
        <div className="auth-page-wrapper">
        <div className="sign-up-card">
            <h2 className="auth-header">Sign Up</h2>
            {error && <div className="error">{error}</div>}
            <form onSubmit={handleSubmit} className="auth-form sign-up-form">
            <div className="auth-form-group">
                    <label htmlFor="" className="auth-form-label">Name</label>
                    <input type="text" ref={nameRef} className="auth-form-input" required/>
                </div>
                <div className="auth-form-group">
                    <label htmlFor="" className="auth-form-label">Email</label>
                    <input type="email" ref={emailRef} className="auth-form-input" required/>
                </div>
                <div className="auth-form-group">
                    <label htmlFor="" className="auth-form-label">Password</label>
                    <input type="password" ref={passwordRef} className="auth-form-input" required/>
                </div>
                <div className="auth-form-group">
                    <label htmlFor="" className="auth-form-label">Password Confirmation</label>
                    <input type="password" ref={passwordConfirmRef} className="auth-form-input" required/>
                </div>
                <button disabled={loading} type="submit" className="button-stretch">Sign Up</button>
            </form>
            <p style={{ textAlign: 'center', color: 'black', fontSize: '13px' }}>By creating an account, you agree to the <a style={{ color: 'var(--primary-clr)' }} href="https://cdn.wowbyann.com/files/waiver.pdf">liability waiver below.</a></p>
        </div>
        <div className="switch-auth-domain">
            Already have an account? <Link style={{ color: 'var(--primary-clr)' }} to='/login'>Log in</Link>
        </div>
        </div>
    )
}
