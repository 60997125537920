import React, { useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import { db } from '../../firebase';
import { getDoc, doc } from 'firebase/firestore';


export default function AdminRoute({ children }) {
    
    const { currentUser } = useAuth();

    if (!currentUser) return <Navigate to='/login' />;

    return (
        currentUser.uid == 'qK3CWSEYeDU5261DvUO8PkUC4d33' || 
        currentUser.uid == 'vIQtkcK3R8NPKOKGMquYYcS0BPB3' ) 
        ? children : <Navigate to='/' />;
    
}