import React, { useState, useEffect } from 'react';
import SideMenu from './SideMenu';
import CourseCard from './courses/CourseCard';
import BeginnersCourseLive from './beginners-course/BeginnersCourseLive';
import BeginnersCourseRecorded from './beginners-course/BeginnersCourseRecorded';
import { useAuth } from '../../contexts/AuthContext';
import { collection, getDoc, getDocs, doc, query, where } from 'firebase/firestore';
import { db } from '../../firebase';


function BeginnersCourse() {

  const { currentUser } = useAuth();
  let user = currentUser.uid;
  const [currentTab, setCurrentTab] = useState('live');
  const [liveClassTab, setLiveClassTab] = useState('active');
  const [recordedClassTab, setRecordedClassTab] = useState('');

  
  function changeTab(tab) {
    setCurrentTab(tab);
    if (tab === 'live') {
      setLiveClassTab('active');
      setRecordedClassTab('');
    }
    else {
      setLiveClassTab('');
      setRecordedClassTab('active');
    }
  }

  if (currentTab == 'live') {
    return (
      <div className="dashboard-wrapper">
          <SideMenu currentTab='beginners-course' />
          <div className="main-content">
            <div className="tab-links-wrapper">
              <div onClick={() => changeTab('live')} className={'tab-link-wrapper ' + liveClassTab}>
                <h4 className="tab-link-text">
                  Live Class
                </h4>
                <div className="tab-link-underline">
                </div>
              </div>
              <div onClick={() => changeTab('recorded')} className={'tab-link-wrapper ' + recordedClassTab}>
                <h4 className="tab-link-text">
                  Recorded Class
                </h4>
                <div className="tab-link-underline">
                </div>
              </div>
            </div>
            <BeginnersCourseLive />
          </div>
      </div>
    );
  }

  if (currentTab == 'recorded') {
    return (
      <div className="dashboard-wrapper">
          <SideMenu currentTab='beginners-course' />
          <div className="main-content">
            <div className="tab-links-wrapper">
              <div onClick={() => changeTab('live')} className={'tab-link-wrapper ' + liveClassTab}>
                <h4 className="tab-link-text">
                  Live Class
                </h4>
                <div className="tab-link-underline">
                </div>
              </div>
              <div onClick={() => changeTab('recorded')} className={'tab-link-wrapper ' + recordedClassTab}>
                <h4 className="tab-link-text">
                  Recorded Class
                </h4>
                <div className="tab-link-underline">
                </div>
              </div>
            </div>
            <BeginnersCourseRecorded />
          </div>
      </div>
    );
  }


}

export default BeginnersCourse;